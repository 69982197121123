import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFirestore } from '@angular/fire/firestore'
import * as firebase from 'firebase/app'
import { Observable, of } from 'rxjs'
import { map, switchMap, take, tap } from 'rxjs/operators'
import { ServerValidation } from '../models/server.validation'
import { User } from '../models/user'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: Observable<User>

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          // logged in, get custom user from Firestore
          return this.afs
            .doc<User>(`users/${user.uid}`)
            .valueChanges()
            .pipe(map(u => ({ ...u, emailVerified: true })))
        } else {
          // logged out, null
          return of(null)
        }
      }),
    )
  }

  getAuthState() {
    return this.afAuth.authState;
  }
  
  facebookAuth(): Promise<firebase.auth.UserCredential | any> {
    const provider = new firebase.auth.FacebookAuthProvider()
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => res)
      .catch(err => console.warn(err))
  }

  login(email: string, password: string): Promise<ServerValidation | undefined> {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(userCredential => undefined)
      .catch(error => this.convertLoginErrors(error))
  }

  logout() {
    return this.afAuth.auth.signOut()
  }

  forgotten(email: string): Promise<ServerValidation | undefined> {
    return this.afAuth.auth
      .sendPasswordResetEmail(email)
      .then(success => undefined)
      .catch(error => this.convertLoginErrors(error))
  }

  sendEmailVerification() {
    this.afAuth.authState.pipe(take(1)).subscribe(user => {
      if (user) {
        user.sendEmailVerification().then(() => {
          window.location.reload()
        })
      }
    })
  }

  private isLoggedInWithFacebook(user: firebase.User) {
    const hasUser = user != null
    const hasProviderData = hasUser && user.providerData != null && user.providerData.length > 0
    const hasProviderId = hasProviderData && user.providerData[0] != null && user.providerData[0].providerId != null

    return hasProviderId ? user.providerData[0].providerId.includes('facebook.com') : false
  }

  private convertLoginErrors(error): ServerValidation {
    if (error.code === 'auth/invalid-email') {
      return { controlName: 'email', validation: { email: true } }
    }

    if (error.code === 'auth/user-not-found') {
      return { controlName: 'email', validation: { userNotFound: true } }
    }

    if (error.code === 'auth/wrong-password') {
      return { controlName: 'password', validation: { wrongPassword: true } }
    }
  }

  private handleError(error) {
    console.error(error)
  }
  getUser() {
    return this.user;
  }
}
