import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
  { path: 'calendar', loadChildren: './calendar/calendar.module#CalendarModule' },
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule', },
  {
    path: 'login', loadChildren: './login/login.module#LoginModule',
  },
  { path: '**', redirectTo: 'calendar', pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
