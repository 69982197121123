import { Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { AuthService } from './login/services/auth.service'

@Component({
  selector: 'app-root',
  template: `
    
    <router-outlet #outlet="outlet"></router-outlet>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  login = false
  forgotten = false
  registration = false

  user

  navigationEndSub: Subscription

  constructor(private router: Router, private auth: AuthService) {
    this.user = this.auth.user

    // this.auth.login('test3@infrashape.com', 'infra1234')
  }

  ngOnInit() {
    this.navigationEndSub = this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe(navigationEnd => {
        window.scrollTo(0, 0)

        const tree = this.router.parseUrl(this.router.url)
        if (tree.fragment) {
          const element = document.querySelector('#' + tree.fragment)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block:'start' })
          }
        }
      })
  }

  ngOnDestroy(): void {
    this.navigationEndSub.unsubscribe()
  }

  prepareRoute(outlet: RouterOutlet) {
    const animation = 'animation'
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData[animation]
  }

  goToMainPage() {
    this.router.navigate(['/'])
  }
}
