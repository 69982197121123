import { Component } from '@angular/core'

@Component({
  selector: 'app-error-box',
  template: `
    <span>
      {{ error }}
    </span>
  `,
  styleUrls: ['./error-box.component.scss'],
})
export class ErrorBoxComponent {
  error
}
