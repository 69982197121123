import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'app-card',
  template: `
    <div class="background-overlay" (click)="closeCard()"></div>
    <div class="card-container" [ngClass]="{'big-card': size}">
      <div class="card-header">
        <div class="title">
          <span class="my-auto">{{ title }}</span>
          <mat-icon (click)="closeCard()">close</mat-icon>
        </div>
      </div>
      <div class="card-body">
        <ng-content select="[id=body]"></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() title: string
  @Input() size: boolean = false;
  @Output() close = new EventEmitter()

  constructor() { }

  closeCard() {
    this.close.emit()
  }
}
