import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-header',
  template: `
    <span (click)="mainPage()"> iconHome</span>
    <span *ngIf="userEmail">Hello {{ userEmail }}</span>
    <button mat-flat-button (click)="handleClick()">
      {{ userEmail == null ? 'Bejelentkezés' : 'Kijelentkezés' }}
    </button>
  `,
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() userEmail

  @Output() login = new EventEmitter()
  @Output() logout = new EventEmitter()
  @Output() goToMainPage = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  handleClick() {
    if (this.userEmail == null) {
      this.login.emit()
    } else {
      this.logout.emit()
    }
  }

  mainPage() {
    this.goToMainPage.emit()
  }
}
